var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-alert-circle-outline ")]),_c('span',{staticClass:"pl-2"},[_vm._v("Driver Schedule Conflicts")])],1),_c('v-data-table',{attrs:{"item-key":"id","loading":_vm.isLoading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.driverConflicts,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayDriver(item.driver.id)}}},[_vm._v(_vm._s(item.driver.name))])]}},{key:"item.intersect_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.intersect_date,"LL")))])]}},{key:"item.current_trip",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayTrip(item.current_trip.id)}}},[_vm._v(_vm._s(item.current_trip.id)+" "),_c('div',[_c('strong',[_vm._v("Route:")]),_vm._v(" "+_vm._s(item.current_trip.route.name)+" ")]),_c('div',[_c('strong',[_vm._v("Start:")]),_vm._v(" "+_vm._s(_vm._f("moment")(item.current_trip.first_stop_time,"LT"))+" ")]),_c('div',[_c('strong',[_vm._v("End:")]),_vm._v(" "+_vm._s(_vm._f("moment")(item.current_trip.last_stop_time,"LT"))+" ")])])]}},{key:"item.current_trip_calender",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.viewTripCalendar(item.current_trip)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-calendar ")])],1)]}},{key:"item.other_trip",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayTrip(item.other_trip.id)}}},[_vm._v(_vm._s(item.other_trip.id)+" "),_c('div',[_c('strong',[_vm._v("Route:")]),_vm._v(" "+_vm._s(item.other_trip.route.name)+" ")]),_c('div',[_c('strong',[_vm._v("Start:")]),_vm._v(" "+_vm._s(_vm._f("moment")(item.other_trip.first_stop_time,"LT"))+" ")]),_vm._v(" "),_c('div',[_c('strong',[_vm._v("End:")]),_vm._v(" "+_vm._s(_vm._f("moment")(item.other_trip.last_stop_time,"LT"))+" ")])])]}},{key:"item.other_trip_calender",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","small":"","color":"error"},on:{"click":function($event){return _vm.viewTripCalendar(item.other_trip)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-calendar ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }